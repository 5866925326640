import { Grid, Stack, Tab, Tabs, Box, FormControl } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PositionInfo from './positionInfo';
import { a11yProps } from 'utils/tabPanels';
import { CustomButton, ModalConfirmation } from '@nexxus/components';
import ClientService from 'services/clientService';
import { toast } from 'react-toastify';
import projectService from 'services/projectService';
import SkillsInfo from './skillsInfo';
import _ from 'lodash';
import WidgetIntake from './widgetIntake';
import { useTheme } from '@mui/styles';
import http from 'services/httpService';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { hookFormTrimValues } from '../../../utils/utils';
import useSignedS3Url from '../../../hooks/useSignedS3Url';
import { UPLOAD_LOCATIONS } from '@nexxus/constants';
import IntakeFormFields from '../IntakeForm/IntakeFormFields';
import IndustryExperienceOptions from 'pages/tas/candidates/ExtraDataCandidate/IndustryExperienceOptions';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import { CLIENTSTATUS } from 'utils/staticData';
import { useGetCustomersQuery } from 'api/api';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  const theme = useTheme();

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, [theme.breakpoints.down('md')]: { px: 0 } }}>{children}</Box>}
    </Box>
  );
};
const WrapperIntakeForm = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isTasAdmin = false } = props;

  const [skills, setSkills] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [initialStateRequest, setInitialStateRequest] = useState({
    ClientId: props.client?.Client?.id,
    intake_position_title: '',
    intake_seniority: '',
    intake_headcount: '',
    intake_maxrate_usd: '',
    intake_description: '',
    intake_timezone: '',
    intake_holidays: '',
    intake_working_hours_open: '',
    intake_working_hours_close: '',
    intake_interview_process: '',
    intake_job_description: '',
    intake_codetest_link: '',
    intake_codetest_file: '',
    intake_hardware_needs: '',
    intake_status: 'DRAFT',
    industry_experienceCV: [],
    intake_skills: [
      { SkillId: '', yearExperience: '', type: '' },
      { SkillId: '', yearExperience: '', type: '' },
      { SkillId: '', yearExperience: '', type: '' },
      { SkillId: '', yearExperience: '', type: '' },
      { SkillId: '', yearExperience: '', type: '' },
    ],
  });
  const [discard, setDiscard] = useState(false);
  const [modified, setModified] = useState(false);
  const [loaderWidget, setLoaderWidget] = useState(true);
  const [modalInfo, setModalInfo] = useState({ header: 'Save DRAFT', body: 'Do you want save your information?' });
  const [files, setFiles] = useState([]);
  const { handleFileUpload } = useSignedS3Url();
  const [valueTab, setValueTab] = useState(0);

  const [showMatchWidget, setShowMatchWidget] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    clearErrors,
    watch,
    alertFormNotification,
    setAlertMessage,
  } = IntakeFormFields();

  const handleChange = useCallback(
    (event, newValue) => {
      setValueTab(newValue);
    },
    [setValueTab]
  );

  const isSubmitted = getValues('intake_status') === 'SUBMITTED';;
  const watchPosition = watch('intake_position_title');
  const watchSeniority = watch('intake_seniority');
  const watchMaxrate = watch('intake_maxrate_usd');
  const watchSkills = watch('intake_skills');

  useMemo(() => {
    const fieldsDraft = ['intake_position_title', 'intake_seniority', 'intake_headcount'];
    const fieldsAll = [
      ...fieldsDraft,
      'intake_maxrate_usd',
      'intake_timezone',
      'intake_working_hours_open',
      'intake_working_hours_close',
      'intake_description',
      'intake_interview_process',
      'intake_job_description',
      'intake_hardware_needs',
      'intake_codetest_file',
      'industry_experienceCV'
    ];

    const check = getValues('intake_status') === 'DRAFT' ? fieldsDraft : fieldsAll;

    let changeTabError = 0;
    check.forEach((prop) => {
      if (_.has(errors, prop)) changeTabError = 1;
    });

    if (changeTabError === 1) {
      setValueTab(0);
      setAlertMessage('invalid');
    } else if (!_.isEmpty(errors)) {
      setValueTab(1);
      setAlertMessage('invalid');
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [errors, getValues, setAlertMessage]);

  useMemo(() => {
    setValue('ClientId', props.client?.Client?.id);
    setInitialStateRequest((prev) => ({ ...prev, ClientId: props.client?.Client?.id }));
  }, [props.client?.Client?.id, setValue]);

  //init Hook
  useEffect(() => {
    (async () => {
      const { data } = await projectService.getAllSkills();
      setSkills(data.map((el) => ({ id: el.id, label: el.name, disabled: el.isActive === false })));
    })();
  }, []);

  useEffect(() => {
    if (modified) props.setHasChanged(true);
  }, [modified, props]);

  const handleTrySubmit = (e) => {
    clearErrors();
    if (props.project?.intake_status === 'SUBMITTED') {
      setValue('intake_status', 'ACTIVE')
    } else{
      if (e.target.name === 'intake') setValue('intake_status', 'INTAKE');
      else if (getValues('intake_status') === 'INTAKE') setValue('intake_status', 'DRAFT');
    }
    handleSubmit(onSubmit)();
  };

  const onSubmit = async () => {
    setConfirm(true);
    setAlertMessage('dismiss');
  };

  const handleSkills = useCallback(
    (values) => {
      if (modified === false) setModified(true);
      // @ts-ignore
      return setValue(`intake_skills`, values);
    },
    [modified, setValue]
  );

  useEffect(() => {
    (async () => {
      setLoaderWidget(true);
      const controller = new AbortController();
      const skillsRequest = [];

      // @ts-ignore
      const stateSkills = _.every(watchSkills, (obj) => _.every(obj, (val) => val === ''));
      const stateMatch = [!!watchPosition, !!watchSeniority, !!watchMaxrate, !stateSkills].some((el) => el === true);

      watchSkills.forEach((sk) => {
        if (sk.SkillId !== '') skillsRequest.push(sk);
      });

      const getCandidates = async () => {
        try {
          if (props.client?.Client?.id) {
            let rows;

            const { data } = await http.get(
              `${process.env.REACT_APP_API_BACKEND}/v1/clients/${props.client?.Client?.id}/candidates_match`,
              {
                params: {
                  candidateFilters: {
                    position: watchPosition,
                    seniority: watchSeniority,
                    maxrate: watchMaxrate,
                    skills: skillsRequest,
                  },
                },
                signal: controller.signal,
              }
            );
            if (skillsRequest.length > 0) {
              if (data.length <= 6) {
                const skillsRequestMust = skillsRequest.filter((sk) => ['', ' ', 'must'].includes(sk.type));
                const skillsRequestNice = skillsRequest.filter((sk) => sk.type === 'nice');
                rows = calculateCandidatesMatch(data, skillsRequestMust, skillsRequestNice);
              } else rows = data;
            } else rows = data;
            setCandidates(rows);
          }
          setLoaderWidget(false);
        } catch (err) {
          // if (err?.message == 'canceled') return console.log('request canceled');
          // if (err?.response?.data) return console.log('error: ', err);
        }
      };
      await getCandidates();
      setShowMatchWidget(stateMatch);
      return () => {
        controller.abort();
      };
    })();
  }, [props.client?.Client?.id, watchPosition, watchSeniority, watchMaxrate, watchSkills]);

  const calculateCandidatesMatch = (candidates, skillsRequestMust, skillsRequestNice) => {
    return candidates.map((cand) => {
      const percentMust = cand.counterMustArr > 0 ? (Number(cand.counterMustArr) * 100) / skillsRequestMust.length : 0;
      const percentNice = cand.counterNiceArr > 0 ? (Number(cand.counterNiceArr) * 100) / skillsRequestNice.length : 0;
      const factorMust = skillsRequestNice.length > 0 ? 0.9 : 1;
      const factorNice = skillsRequestMust.length > 0 ? 0.1 : 1;
      return {
        ...cand,
        percentMust: percentMust,
        percentNice: percentNice,
        totalMatchCandidate: percentMust * factorMust + percentNice * factorNice,
      };
    });
  };
  const onCloseConfirm = (event, _reason) => {
    event?.preventDefault();
    setDiscard(false);
    setConfirm(false);
  };
  const onAccept = async (e) => {
    e?.preventDefault();
    props.setHasChanged(false);
    if (discard) {
      handleDiscardChanges();
      setConfirm(false);
      return;
    }
    setConfirm(false);
    setModified(false);
    handleSubmitIntake();
  };

  const handleSubmitIntake = async () => {
    try {
      const fields = hookFormTrimValues(getValues());
      if (files[0] instanceof File) {
        const s3File = await handleFileUpload({
          file: files[0],
          contentType: files[0].type,
          path: UPLOAD_LOCATIONS.PROJECT_ATTACHMENTS,
        });

        if (s3File) {
          fields['intake_codetest_file'] = s3File.object_url;
          fields['meta_uploads'] = { intake_codetest_file: { url: s3File.object_url, originalName: s3File.originalName } };
        }
      }
      fields.intake_hardware_needs = [getValues('intake_hardware_needs')];
      fields.isTasAdmin = isTasAdmin
      if (!_.isEmpty(props.project) && props.project.ProjectId)
        await ClientService.updateIntakePosition(fields, props.project.ProjectId);
      else await ClientService.registerIntakePosition(fields);
      clearErrors();
      setValueTab(0);
      reset();
      setValue('ClientId', props.client?.Client?.id);
      toast.success('Intake saved successfully! ');
      if (isTasAdmin) {
        navigate('/tas/projects/saved-intake-form');
      } else {
        navigate('/customer/projects/intake/list');
      }
    } catch (e) {
      let err = '';
      if (typeof e?.response?.data?.message === 'string') {
        err = e.response.data.message;
      } else if (typeof e?.response?.data === 'string') {
        err = e.response.data;
      } else if (e.message) {
        err = e.message;
      }
      return toast.error(err);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(props.project)) {
      const body = {
        ClientId: props.project.ClientId,
        intake_position_title: props.project.intake_position_title,
        intake_seniority: props.project.intake_seniority,
        intake_headcount: props.project.intake_headcount,
        intake_maxrate_usd: props.project.intake_maxrate_usd,
        intake_description: props.project.intake_description,
        intake_timezone: props.project.intake_timezone,
        intake_holidays: props.project.intake_holidays,
        intake_working_hours_open: props.project.intake_working_hours_open,
        intake_working_hours_close: props.project.intake_working_hours_close,
        intake_interview_process: props.project.intake_interview_process,
        intake_job_description: props.project.intake_job_description,
        intake_codetest_link: props.project.intake_codetest_link,
        intake_hardware_needs: props.project?.intake_hardware_needs?.[0] ?? '',
        intake_status: props.project?.intake_status || 'DRAFT',
        intake_skills: props.project.intake_skills,
        intake_codetest_file: props.project.intake_codetest_file ?? '',
        industry_experienceCV: props.project.industry_experienceCV || [],
      };
      reset({ ...body });
      setInitialStateRequest({ ...body });
    }
  }, [props.project, reset]);

  const isFullWidth = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (discard) {
      setModalInfo({ header: 'Clear changes', body: 'Do you want to discard your changes?' });
      setConfirm(true);
    }
  }, [discard]);

  const handleDiscardChanges = async () => {
    clearErrors();
    reset(initialStateRequest);
    setModified(false);
    setValueTab(0);
    props.setHasChanged(false);
  };

  const fileHandler = useCallback(
    (files) => {
      setModified(true);
      if (files.length > 0) {
        setFiles(files);
        setValue(`intake_codetest_file`, files[0].name);
      } else {
        setValue(`intake_codetest_file`, '');
        setFiles([]);
      }
    },
    [setValue]
  );

  const {
    data: customers,
  } = useGetCustomersQuery({ status: CLIENTSTATUS.ACTIVE });

  const handleSaveIndustryExperiece = (value) => {
    setValue('industry_experienceCV', value);
    props.setHasChanged(true);
    setModified(!modified);
  }

  return (
    <Box sx={{ mt: 2 }}>
      <ModalConfirmation
        variant="info"
        headerText={modalInfo.header}
        titleText={modalInfo.body}
        open={confirm}
        onCancel={onCloseConfirm}
        onClose={onCloseConfirm}
        onAccept={onAccept}
        iconVariant={'info'}
        headerBarColor={theme.palette.primary.main}
      />
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems={'stretch'} sx={{ minHeight: '675px' }}>
        <Grid item xs={12} md={12} lg={3} xl={3} sx={{display: props.hideWidget ? 'none' : 'inherit'}}>
          <Box
            sx={{
              [theme.breakpoints.up('lg')]: { display: 'flex', alignItems: 'stretch', height: '100%' },
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                padding: '16px',
                background: 'white',
                borderRadius: '5px',
                width: '100%',
                [theme.breakpoints.up('lg')]: { height: '100%' },
              }}
            >
              <WidgetIntake
                customer={props.client?.Client}
                loading={loaderWidget}
                candidates={candidates}
                skills={watchSkills}
                showmatch={showMatchWidget}
              ></WidgetIntake>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={ props.hideWidget ? 12 : 9} xl={ props.hideWidget ? 12: 9}>
          <Stack
            direction="column"
            sx={{ background: 'white', padding: '16px', width: '100%', height: '100%' }}
            alignItems={'stretch'}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              alignSelf={'stretch'}
              sx={{ borderRadius: '5px', width: '100%' }}
            >
              <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <Tabs value={valueTab} onChange={handleChange} variant="fullWidth" aria-label="basic tabs">
                  <Tab label="Project Information" {...a11yProps(0)} />
                  <Tab label="Skills" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={valueTab} index={0}>
                <Grid item xs={12}>
                  {alertFormNotification('invalid', { mb: 2 })}
                </Grid>
                <Stack marginBottom={2} direction={'row'} alignItems={'flex-start'}>
                  {/* Client  */}
                  <FormControl fullWidth>
                    {customers && (
                      <InputAutoCompleteV2
                        placeholder={'Customer'}
                        id={'ClientId'}
                        error={errors.ClientId?.message}
                        opts={customers.map((client) => ({ _id: client.id, label: client.companyName }))}
                        initialValue={initialStateRequest.ClientId}
                        onChangeInput={(val) => {
                          setInitialStateRequest({...initialStateRequest, ClientId: val.target.value});
                          setValue('ClientId', val.target.value);
                        }}
                        size={'normal'}
                        defaultOption={false}
                      />
                    )}
                  </FormControl>
                </Stack>
                <PositionInfo
                  values={getValues()}
                  setValue={setValue}
                  errors={errors}
                  disabled={!props.editing}
                  initialState={initialStateRequest}
                  setModified={setModified}
                  onFileHandler={fileHandler}
                  uploads={props.project.intake_uploads}
                />
              </TabPanel>
              <TabPanel value={valueTab} index={1}>
                <Grid item xs={12}>
                  {alertFormNotification('invalid', { mb: 2 })}
                </Grid>
                <SkillsInfo
                  skillsOpts={skills}
                  initialStateRequest={initialStateRequest}
                  values={getValues()}
                  errors={errors}
                  disabled={!props.editing}
                  onHandleSkills={handleSkills}
                ></SkillsInfo>
                {/* indrustry experience */}
                <IndustryExperienceOptions
                  handleSave={handleSaveIndustryExperiece}
                  editingFields={!props.editing}
                  rowOptions={getValues('industry_experienceCV')}
                  useTargeFormat={false}
                />
              </TabPanel>
            </Stack>
            <Stack
              direction={'row'}
              spacing={4}
              justifyContent={'flex-end'}
              py={3}
              px={{ sm: 0, md: 3 }}
              flexWrap={'wrap'}
              gap={2}
            >
              {props.editing ? (
                <>
                  <CustomButton
                    id="discard"
                    customType="button"
                    fullWidth={isFullWidth}
                    sx={{
                      height: '36px',
                      fontSize: '16px',
                      mr: 'auto',
                      [theme.breakpoints.up('sm')]: { minWidth: '205px' },
                    }}
                    colorfull={false}
                    type="button"
                    onClick={() => setDiscard(true)}
                    disabled={!modified}
                  >
                    Clear
                  </CustomButton>
                  {!isSubmitted && <CustomButton
                    id="draft"
                    customType="button"
                    fullWidth={isFullWidth}
                    sx={{
                      height: '36px',
                      fontSize: '16px',
                      [theme.breakpoints.up('sm')]: { minWidth: '205px' },
                      [theme.breakpoints.down('md')]: { ml: '0px!important' },
                    }}
                    colorfull={false}
                    type="button"
                    onClick={(e) => {
                      setModalInfo({ header: 'Save DRAFT', body: 'Do you want to save your information?' });
                      handleTrySubmit(e);
                    }}
                    disabled={false}
                  >
                    SAVE FOR LATER
                  </CustomButton>}
                  <CustomButton
                    id="intake"
                    customType="button"
                    sx={{
                      height: '36px',
                      fontSize: '16px',
                      [theme.breakpoints.up('sm')]: { minWidth: '205px' },
                      [theme.breakpoints.down('sm')]: { margin: '0px!important' },
                      [theme.breakpoints.down('md')]: { ml: '0px!important' },
                    }}
                    colorfull={true}
                    type="button"
                    disabled={false}
                    onClick={(e) => {
                      setModalInfo({ header: 'Save INTAKE', body: 'Do you want to send your information?' });
                      handleTrySubmit(e);
                    }}
                    fullWidth={isFullWidth}
                  >
                    {isSubmitted && !isTasAdmin ? 'APPROVE' : 'CONTINUE'}
                  </CustomButton>
                </>
              ) : (
                  <CustomButton
                    id="discard"
                    customType="button"
                    fullWidth={isFullWidth}
                    sx={{
                      height: '36px',
                      fontSize: '16px',
                      mr: 'auto',
                      [theme.breakpoints.up('sm')]: { minWidth: '205px' },
                    }}
                    colorfull={false}
                    type="button"
                    onClick={() => navigate('/customer/projects/intake/list')}
                    disabled={false}
                  >
                    Go back
                  </CustomButton>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WrapperIntakeForm;
