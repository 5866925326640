import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosPrivate } from './axios';
import { signout } from '../store/auth';
import _, { isEmpty } from 'lodash';
import DOMPurify from 'dompurify';

const axiosBaseQuery =
  ({ baseUrl, headers }) =>
  async ({ url, method, data, params }, { dispatch }) => {
    try {
      const result = await axiosPrivate({ url: baseUrl + url, method, data, params, headers });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      if (axiosError.response?.status === 401) {
        dispatch(signout());
      }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BACKEND}/v1/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('tokens'))?.access?.token}`,
    },
  }),
  tagTypes: ['Users', 'Projects', 'Recruiters', 'Skills', 'Tags', 'Candidates', 'Customers', 'NotificationsWidget'],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (params) => ({
        url: 'users',
        method: 'GET',
        data: null,
        params,
      }),
      providesTags: ['Users'],
    }),
    getUsersByCustomer: builder.query({
      query: (params) => ({
        url: 'users/customer',
        method: 'GET',
        data: null,
        params,
      }),
      providesTags: ['Users'],
    }),
    getNotificationsWidget: builder.query({
      query: (params) => ({
        url: 'notifications',
        method: 'GET',
        data: params,
        params,
      }),
      providesTags: ['NotificationsWidget'],
      transformResponse: (response) => {
        const newResponse = {
          notifications: [],
          totalNotRead: 0,
          totalReaded: 0,
          totalNotifications: response?.count,
        }

        response?.notifications?.forEach((not, index) => {
          newResponse.notifications.push(not);
          newResponse.notifications[index].meta.shortMessage = DOMPurify.sanitize(_.unescape(not.meta.shortMessage), {
            USE_PROFILES: { html: true },
          });
          if(not.markAsRead) newResponse.totalReaded += 1;
          else newResponse.totalNotRead += 1;
        });

        return newResponse;
      },
    }),
    getTasNotifications: builder.query({
      query: (params) => ({
        url: 'notifications/tas',
        method: 'GET',
        data: params,
        params,
      }),
      transformResponse: (response) => {
        const newResponse = {
          notifications: [],
          totalNotRead: 0,
          totalReaded: 0,
          totalNotifications: response?.count,
        }

        response?.notifications?.forEach((not, index) => {
          newResponse.notifications.push(not);
          newResponse.notifications[index].meta.shortMessage = DOMPurify.sanitize(_.unescape(not.meta.shortMessage), {
            USE_PROFILES: { html: true },
          });
          if(not.markAsRead) newResponse.totalReaded += 1;
          else newResponse.totalNotRead += 1;
        });

        return newResponse;
      },
    }),
    restoreUser: builder.mutation({
      query: ({ userId, data }) => ({
        url: `users/restore/${userId}`,
        method: 'PATCH',
        data,
        params: null,
      }),
      invalidatesTags: ['Users'],
    }),
    getCurrentUser: builder.query({
      query: () => ({
        url: 'users/me',
        method: 'GET',
        data: null,
        params: null,
      }),
      keepUnusedDataFor: 0,
    }),
    setLastUserActivity: builder.mutation({
      query: () => ({
        url: 'users/me/keep-alive',
        method: 'GET',
        data: null,
        params: null,
      }),
    }),
    getProjects: builder.query({
      query: (params) => ({
        url: 'projects',
        method: 'GET',
        data: null,
        params,
      }),
      providesTags: ['Projects'],
    }),
    getRecruiters: builder.query({
      query: (params) => ({
        url: 'projects/recruiters',
        method: 'GET',
        data: null,
        params,
      }),
      providesTags: ['Recruiters'],
    }),
    getSkills: builder.query({
      query: (params) => ({
        url: 'skills/all',
        method: 'GET',
        data: null,
        params,
      }),
      providesTags: ['Skills'],
    }),
    getSkillsResume: builder.query({
      query: (params) => ({
        url: 'skills/resume',
        method: 'GET',
        data: null,
        params,
      }),
      providesTags: ['Skills'],
    }),
    getTags: builder.query({
      query: () => ({
        url: 'tags/all',
        method: 'GET',
        data: null,
        params: null,
      }),
      providesTags: ['Tags'],
      transformResponse: (response) => {
        const sortTags = (data, propSort, startWithArray) => {
          const newArr = data.slice().sort((a, b) => a[propSort].localeCompare(b[propSort])); // Sort alphabetically based on 'name' property
          const movedToEnd = [];
          const remaining = [];
          newArr.forEach((obj) => {
            if (startWithArray.some((prefix) => obj[propSort].startsWith(prefix))) {
              movedToEnd.push(obj);
            } else {
              remaining.push(obj);
            }
          });

          return remaining.concat(movedToEnd);
        };

        return sortTags(response, 'name', ['.']);
      },
    }),
    addTag: builder.mutation({
      query: (data) => ({
        url: 'tags',
        method: 'POST',
        data,
        params: null,
      }),
      invalidatesTags: ['Tags'],
    }),
    getCandidates: builder.query({
      query: (params) => ({
        url: 'candidates',
        method: 'GET',
        data: null,
        params,
      }),
      providesTags: ['Candidates'],
    }),
    getCandidateById: builder.query({
      query: (candidateId) => ({
        url: `candidates/${candidateId}`,
        method: 'GET',
        data: null,
        params: null,
      }),
      providesTags: (result, error, candidateId) => [{ type: 'Candidates', id: candidateId }],
    }),
    updateCandidateFile: builder.mutation({
      query: ({ candidateId, file_type, data }) => ({
        url: `candidates/${candidateId}/${file_type}/`,
        method: 'POST',
        data,
        params: null,
      }),
      invalidatesTags: (result, error, { candidateId }) => [{ type: 'Candidates', id: candidateId }],
    }),
    deleteCandidateAudio: builder.mutation({
      query: ({ audioId }) => ({
        url: `candidates/audios/${audioId}`,
        method: 'DELETE',
        data: null,
        params: null,
      }),
      invalidatesTags: (result, error, { candidateId }) => [{ type: 'Candidates', id: candidateId }],
    }),
    getCandidateFeedback: builder.query({
      query: (candidateId) => ({
        url: `candidates/${candidateId}/feedback`,
        method: 'GET',
        data: null,
        params: null,
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        let feedback = [];
        response.map((entry) => {
          Object.keys(entry.customer_feedback).forEach((key) => {
            const baseFeedback = {
              customer: entry.Project.Client.companyName,
              position: entry.Project.position,
              seniority: entry.Project.seniority,
              pipeline_stage: key,
              feedback: {},
              date: '',
            };
            if (!isEmpty(entry.customer_feedback[key]?.feedback)) {
              feedback.push({
                ...baseFeedback,
                feedback: entry.customer_feedback[key]?.feedback,
                date: entry.customer_feedback[key]?.dateStatus,
              });
            }
            if (!isEmpty(entry.customer_feedback[key]?.historical)) {
              entry?.customer_feedback?.[key]?.historical.forEach((historical) => {
                if (!isEmpty(historical.feedback))
                  // ignore empty feedback
                  feedback.push({
                    ...baseFeedback,
                    feedback: historical.feedback,
                    date: historical.dateStatus,
                  });
              });
            }
          });
        });
        return feedback;
      },
    }),
    getCustomers: builder.query({
      query: (params) => ({
        url: 'clients',
        method: 'GET',
        data: null,
        params,
      }),
      providesTags: ['Customers'],
    }),
    getCustomerById: builder.query({
      query: (CustomerId) => ({
        url: `clients/${CustomerId}`,
        method: 'GET',
        data: null,
        params: null,
      }),
      providesTags: (result, error, id) => [{ type: 'Customers', id }],
    }),
    createCustomer: builder.mutation({
      query: (data) => ({
        url: 'clients',
        method: 'POST',
        data,
        params: null,
      }),
      invalidatesTags: ['Customers'],
    }),
    updateCustomer: builder.mutation({
      query: ({ CustomerId, data }) => ({
        url: `clients/${CustomerId}`,
        method: 'PATCH',
        data,
        params: null,
      }),
      invalidatesTags: (result, error, { CustomerId }) => [{ type: 'Customers', id: CustomerId }],
    }),
    getLogs: builder.query({
      query: (params) => ({
        url: 'logs',
        method: 'GET',
        data: null,
        params,
      }),
    }),
    getCandidateNotes: builder.query({
      query: (params) => ({
        url: `candidates/${params.candidateId}/notes`,
        method: 'GET',
        data: null,
        params,
      }),
    }),
    initEmmersionSync: builder.mutation({
      query: (params) => ({
        url: `candidates/get-english-assessment/${params.candidateId}`,
        method: 'GET',
        data: null,
        params: null,
      }),
    }),
    getCustomersPendingFeedback: builder.query({
      query: (params) => ({
        url: `clients/${params.customerId}/candidates-pending-feedback`,
        method: 'GET',
        data: null,
        params,
      }),
      transformResponse: (res) => {
        const allCandidates = [];
        res.data.forEach((pj) => allCandidates.push(...pj.Candidates));
        return { projectsWithCandidates: res.data, totalCandidates: allCandidates.length };
      },
    }),
    getCatalogPhonesCountries: builder.query({
      query: (params) => ({
        url: `catalogs/phone`,
        method: 'GET',
        data: null,
        params,
      }),
      // transformResponse: (res) => {
      //   const allCandidates = [];
      //   res.data.forEach((pj) => allCandidates.push(...pj.Candidates));
      //   return { projectsWithCandidates: res.data, totalCandidates: allCandidates.length };
      // },
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        data,
        params: null,
      }),
    }),
    getAuditTrails: builder.query({
      query: (params) => ({
        url: 'audittrails',
        method: 'GET',
        data: null,
        params,
      }),
    }),
    getAuditTrail: builder.query({
      query: (id) => ({
        url: `audittrails/${id}`,
        method: 'GET',
        data: null,
        params: null,
      }),
    }),
    getCandidateProjectMeta: builder.query({
      query: (params) => ({
        url: `candidates/${params.candidateId}/project-meta/${params.projectId}`,
        method: 'GET',
        data: null,
        params,
      }),
    }),
    updateCandidateProjectMeta: builder.mutation({
      query: ({ candidateId, projectId, data }) => ({
        url: `candidates/${candidateId}/project-meta/${projectId}`,
        method: 'PATCH',
        data,
        params: null,
      }),
    }),
    getCountries: builder.query({
      query: (params) => ({
        url: 'countries',
        method: 'GET',
        data: null,
        params,
      }),
    }),
    getManagedCountries: builder.query({
      query: (params) => ({
        url: 'countries/managed',
        method: 'GET',
        data: null,
        params,
      }),
    }),
    addManagedCountry: builder.mutation({
      query: (data) => ({
        url: 'countries/managed',
        method: 'POST',
        data,
        params: null,
      }),
    }),
    updateManagedCountry: builder.mutation({
      query: ({ CountryId, data }) => ({
        url: `countries/managed/${CountryId}`,
        method: 'PATCH',
        data,
        params: null,
      }),
    }),
    updatePhoneAvailability: builder.mutation({
      query: ({ CountryId, data }) => ({
        url: `countries/managed/phone/${CountryId}`,
        method: 'PATCH',
        data,
        params: null,
      }),
    }),
    getIndustriesExperience: builder.query({
      query: (params) => ({
        url: 'industry-experience',
        method: 'GET',
        data: null,
        params,
      }),
    }),
  }),
});

export const {
  useGetCatalogPhonesCountriesQuery,
  useGetUsersQuery,
  useGetCurrentUserQuery,
  useSetLastUserActivityMutation,
  useGetProjectsQuery,
  useGetRecruitersQuery,
  useGetSkillsQuery,
  useGetSkillsResumeQuery,
  useGetTagsQuery,
  useAddTagMutation,
  useGetCandidatesQuery,
  useGetCandidateByIdQuery,
  useGetCustomersQuery,
  useGetLogsQuery,
  useGetCandidateNotesQuery,
  useInitEmmersionSyncMutation,
  useGetCustomersPendingFeedbackQuery,
  useUpdateCandidateFileMutation,
  useDeleteCandidateAudioMutation,
  useGetCandidateFeedbackQuery,
  useGetUsersByCustomerQuery,
  useRestoreUserMutation,
  useResetPasswordMutation,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useGetCustomerByIdQuery,
  useGetAuditTrailsQuery,
  useGetAuditTrailQuery,
  useGetCandidateProjectMetaQuery,
  useUpdateCandidateProjectMetaMutation,
  useGetManagedCountriesQuery,
  useGetCountriesQuery,
  useAddManagedCountryMutation,
  useUpdateManagedCountryMutation,
  useUpdatePhoneAvailabilityMutation,
  useGetNotificationsWidgetQuery,
  useGetIndustriesExperienceQuery,
  useGetTasNotificationsQuery,
} = api;
