import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { usePrevious } from 'utils/utils';


const stylesInputOutlinedV2 = makeStyles({
  root: {
    // "& $notchedOutline": {
    //   borderWidth: 1
    // },
    // "&:hover $notchedOutline": {
    //   borderWidth: 0
    // },
    // "&$focused $notchedOutline": {
    //   borderWidth: 0
    // }
    '& > .Mui-disabled': {
      color: '#979797!important',
      zIndex: '3',
    },
    '&.Mui-disabled fieldset': {
      background: '#dadada!important',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.26)!important'
    },
    '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
      border: '1px solid #FF5050!important',
    },
  },
  // focused: {},
  // notchedOutline: {}
});

const InputTextOutlinedV2 = ({
  id,
  type,
  label,
  tooltip,
  placeholder,
  error,
  initialValue,
  onChangeInput,
  size,
  multiline,
  rowsMultiline,
  endAdornment,
  startAdornment,
  styleControl,
  styleLabel,
  styleError,
  inputProps,
  isDisabled,
  removeBlankSpaces = false,
  onlyNumbers = false,
  helperText,
  onWheel = () => {},
}) => {
  const [value, setValue] = useState('');
  const [valueDebouce] = useDebounce(value, 400, { leading: false, trailing: true });
  let inputRef = useRef(null);
  const prevValue = usePrevious(valueDebouce);
  const classes = stylesInputOutlinedV2();

  useEffect(() => {
    if (value !== initialValue) setValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    if (typeof prevValue !== 'undefined') {
      const obj = { target: { name: inputRef.current.name, value: inputRef.current.value } };
      if (initialValue === value) {
        return;
      }
      return onChangeInput(obj);
    }
  }, [valueDebouce]); // eslint-disable-line

  const defineKeyDown = (e) => {
    if (onlyNumbers) {
      const forbiddenCharsCodes = {
        quote: 229,
        plusSignNumeric: 107,
        minusSignNumeric: 109,
        dotNumeric: 110,
        plusSign: 187,
        minusSign: 189,
        exponential: 69,
        arrowUp: 38,
        arrowDown: 40,
        dot: 190,
      };
      Object.values(forbiddenCharsCodes).forEach((charKeyCode) => {
        if (e.keyCode === charKeyCode) {
          e.preventDefault();
          e.stopPropagation();
        }
      });
    }
  };

  return (
      <FormControl fullWidth sx={{ textAlign: 'left', ...styleControl }} disabled={isDisabled}>
        {label && label !== '' && (
          <FormLabel error={!!error && error !== ''} htmlFor={id} sx={{ marginTop: 'auto', ...styleLabel }}>
            {label} {tooltip}
          </FormLabel>
        )}
        <OutlinedInput
          inputRef={inputRef}
          fullWidth
          name={id}
          id={id}
          value={value}
          onKeyDown={defineKeyDown}
          onWheel={onWheel}
          onChange={(e) => {
            if (removeBlankSpaces) {
              const fixedText = e.target.value.replaceAll(' ', '');
              setValue(fixedText);
            } else {
              setValue(e.target.value);
            }
          }}
          size={size}
          type={type || 'text'}
          multiline={multiline || false}
          rows={rowsMultiline || 1}
          error={!!error}
          placeholder={placeholder}
          disabled={isDisabled || false}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          // sx={{
          //   ...styleInput,
          // }}
          classes={classes}
          inputProps={inputProps}
        />
        {error ? (
          <FormHelperText error id={id} sx={{ styleError }}>
            {error}
          </FormHelperText>
        ) : (
          helperText
        )}
      </FormControl>
  );
};

InputTextOutlinedV2.propTypes = {
  id: PropTypes.string.isRequired, //id for input
  type: PropTypes.string, // type of text field
  label: PropTypes.string, // form label
  tooltip: PropTypes.element,
  placeholder: PropTypes.string, // placeholder of the field
  error: PropTypes.string, // error message
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // value of the field
  onChangeInput: PropTypes.func, // func callback
  size: PropTypes.string, // size of input
  multiline: PropTypes.bool, //If true, a TextareaAutosize element is rendered.
  rowsMultiline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), //Number of rows to display when multiline option is set to true.
  startAdornment: PropTypes.element, // Adornment for the input at the beginning of field
  endAdornment: PropTypes.element, // Adornment for the input at the end of field
  styleLabel: PropTypes.object, // props to overwrite styles for label control
  styleInput: PropTypes.object, // props to overwrite styles for input control
  styleError: PropTypes.object, // props to overwrite styles for error message
  isDisabled: PropTypes.bool,
  styleControl: PropTypes.object,
  inputProps: PropTypes.object,
  helperText: PropTypes.element,
};

export default InputTextOutlinedV2;
