import {
  Button,
  IconButton,
  Grid,
  Stack,
  Typography,
  styled,
  useTheme,
  Autocomplete,
  TextField,
  Chip,
  FormControl,
  FormLabel,
  Box,
  FormHelperText,
} from '@mui/material';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import { useCallback, useMemo, useState } from 'react';
import { schemaCvTAS } from 'validations/candidateRegister';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InputAutoCompleteV2 from 'components/FormElement/InputAutoCompleteV2';
import Joi from 'joi';
import _ from 'lodash';
import { lightBlue } from '@mui/material/colors';
import ModalValidationSkills from 'pages/tas/candidateForm/modalValidationSkills';
import InputDatePicker from 'components/FormElement/InputDatePicker';
import RowResumeWorkExperience from './rowResumeWorkExperience';
import IndustryExperienceOptions from './IndustryExperienceOptions';
import { languages } from 'pages/tas/constants';

const CustomBtn = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width, theme }) => ({
  width: width ?? 'auto',
  paddingLeft: '0!important',
  paddingRight: '0!important',
  height: '36px',
  borderWidth: '2px',
  fontWeight: '700',
  fontSize: '16px',
  borderRadius: '3px',
  borderColor: '#0242D1',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    marginTop: '10px!important',
    width: '100%',
  },
}));

const CandidateFormAutofilled = ({ candidateFields, editing, skillsOptions }) => {
  const theme = useTheme();
  const [openModalSkills, setOpenModalSkills] = useState(false);

  const { initialStateRequest, fields, handleSave, errors, setValues, setForm, setErrors } = candidateFields;

  const handleWorkExperienceErrors = (id, details, globalErrors, index) => {
    let errorsWe = {};
    for (let item of details) errorsWe[item.context.label] = { response: true, txt: item.message };
    if (errorsWe.we_description) delete errorsWe.we_isValid;
    if (!globalErrors.workExperienceCV) globalErrors.workExperienceCV = [];
    if (!globalErrors.workExperienceCV[index]) globalErrors.workExperienceCV[index] = {};
    return errorsWe;
  };

  const setErrorsCV = useCallback(
    (id, details, index = null) => {
      let globalErrors = _.cloneDeep(errors);
      let errorsCV = {};
      if (details) {
        if (id.startsWith('we')) {
          const err = handleWorkExperienceErrors(id, details, globalErrors, index);
          globalErrors.workExperienceCV[index][id] = err[[id]];
        } else {
          for (let item of details) errorsCV[item.context.label] = { response: true, txt: item.message };
          globalErrors[id] = errorsCV[id];
        }
      } else {
        if (!globalErrors?.workExperienceCV?.[index]?.[id]) return;
        delete globalErrors?.workExperienceCV?.[index]?.[id];
      }

      setErrors(globalErrors);
    },
    [errors, setErrors]
  );

  const handleClearIconClick = useCallback(
    (index) => {
      const wk = [...fields.workExperienceCV];
      wk.splice(index, 1);

      setForm({
        ...fields,
        workExperienceCV: wk,
        resumeRequired: true,
      });
    },
    [errors, fields, setErrors, setForm]
  );

  const handleAddNewWorkExperience = () => {
    setForm({
      ...fields,
      workExperienceCV: [
        ...fields.workExperienceCV,
        {
          we_isValid: false,
          we_startDate: '',
          we_endDate: '',
          we_position: '',
          we_location: '',
          we_company: '',
          we_description: '',
        },
      ],
    });
  };

  const handleErrorSingleOrValid = useCallback(
    (field) => {
      if (errors?.[field]) return errors?.[field]?.txt;
      else if (errors?.[`${field}_valid`]) return 'This Field must be validated for user';
      else return '';
    },
    [errors]
  );

  const handleValidate = useCallback(
    (initial, updated) => {
      let newFields = { ...fields };
      const newSkills = [...fields.skillsCV];
      const findIndex = fields.skillsCV.findIndex((skill) => skill.value === initial);
      newSkills[findIndex] = { ...newFields.skillsCV[findIndex], value: updated.value, validated: true };
      newFields.skillsCV = newSkills;
      setForm(newFields);
    },
    [fields, setForm]
  );

  const handleDeleteSkill = useCallback(
    (index) => {
      let newFields = _.cloneDeep(fields);
      newFields.skillsCV.splice(index, 1);
      setForm(newFields);
    },
    [fields, setForm]
  );

  const handleWorkExperience = useCallback(
    (index, weFields) => {
      let newFields = _.cloneDeep(fields);
      newFields.workExperienceCV[index] = weFields;
      setForm({ ...newFields, resumeRequired: true });
    },
    [fields, setForm]
  );

  const handleValidateByUser = useCallback(
    (field) => {
      const copyFields = _.cloneDeep(fields);
      const copyErrors = _.cloneDeep(errors);
      const sliceSchema = Joi.object({ [field]: schemaCvTAS[field] });
      const { error } = sliceSchema.validate({ [field]: copyFields[field] }, { abortEarly: false });
      if (!error) {
        copyFields[`${field}_valid`] = true;
        setForm(copyFields);
        if (field === 'languagesCV') {
          _.unset(copyErrors, 'languagesCV');
          _.unset(copyErrors, 'languagesCV_valid');
        } else if (field === 'certificationsCV') {
          _.unset(copyErrors, 'certificationsCV');
          _.unset(copyErrors, 'certificationsCV_valid');
        }

        setErrors(copyErrors);
      } else {
        setErrorsCV(field, error?.details);
      }
    },
    [errors, fields, setErrors, setErrorsCV, setForm]
  );

  const handleStateButtonWe = useMemo(() => {
    return !(editing === false && fields?.workExperienceCV?.length > 1);
  }, [editing, fields.workExperienceCV]);

  const handleStateButtonvalid = useCallback(
    (type) => {
      let condition = true;
      if (type === 'languagesCV') condition = !fields.languagesCV_valid;
      else if (type === 'certificationsCV') condition = !fields.certificationsCV_valid;
      return !(!editing && condition);
    },
    [editing, fields.certificationsCV_valid, fields.languagesCV_valid]
  );

  const editingFields = useMemo(() => {
    return !!editing;
  }, [editing]);

  const editingValidateFields = useMemo(() => {
    if (editing) return true;
    else if (fields.skillsCV.length > 0) return false;
    else return true;
  }, [editing, fields.skillsCV]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container mt={2}>
            <Typography variant="h7" sx={{ fontWeight: '700' }}>
              BASIC CANDIDATE INFO
            </Typography>
          </Grid>
          <Grid container spacing={2} mt={0.5}>
            {/* Birthday */}
            <Grid item xs={12} md={6} lg={4} lg3={3}>
              <InputDatePicker
                id={'birthdayCV'}
                label="Birthday"
                onChange={(value) => setValues({ ...fields, birthdayCV: value })}
                initialValue={fields?.birthdayCV}
                error={errors.birthdayCV?.txt}
                isDisabled={editingFields}
              />
            </Grid>
            {/* Years of experience */}
            <Grid item xs={12} md={6} lg={4} lg3={3}>
              <InputTextOutlinedV2
                id={'yearsOfExperienceCV'}
                type={'number'}
                onlyNumbers={true}
                size={'normal'}
                onWheel={(e) => {
                  // Prevent the input value change
                  e.target.blur();
                  e.stopPropagation();
                }}
                label="Years of experience"
                error={errors.yearsOfExperienceCV?.txt}
                initialValue={fields?.yearsOfExperienceCV || ''}
                onChangeInput={handleSave}
                isDisabled={editingFields}
              />
            </Grid>
            {/* Position */}
            <Grid item xs={12} md={6} lg={4} lg3={3}>
              <InputTextOutlinedV2
                id={'positionCV'}
                size={'normal'}
                type="text"
                label="POSITION"
                error={errors.positionCV?.txt}
                initialValue={fields?.positionCV || ''}
                onChangeInput={handleSave}
                isDisabled={editingFields}
              />
            </Grid>
          </Grid>
          {/* SKILLS */}
          <Grid container mt={2}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                alignItems={'flex-end'}
                justifyContent="center"
                sx={{ width: '100%' }}
                flexWrap={{ xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    [theme.breakpoints.down('lg')]: {
                      width: '100%',
                    },
                  }}
                >
                  <FormLabel
                    id={'skillsCV'}
                    className={editing ? 'Mui-disabled' : ''}
                    error={errors?.skillsCV?.length > 0 || errors?.skillsCV?.response}
                    htmlFor={'skillsCV'}
                    sx={{ marginTop: 'auto' }}
                  >
                    SKILLS
                  </FormLabel>
                  <FormControl fullWidth={true} sx={{ textAlign: 'left', justifyContent: 'space-between' }}>
                    <Autocomplete
                      disablePortal
                      id="skillsCV"
                      value={fields?.skillsCV}
                      options={fields?.skillsCV || []}
                      multiple={true}
                      placeholder=""
                      renderInput={({ inputProps, ...rest }) => (
                        <TextField
                          {...rest}
                          inputProps={{ ...inputProps, readOnly: true }}
                          error={errors?.skillsCV?.length > 0 || errors?.skillsCV?.response}
                          sx={{ marginBottom: '0!important' }}
                        />
                      )}
                      open={false}
                      getOptionLabel={(option) => {
                        return option['value'] || '';
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.value}>
                            {option.value}
                          </li>
                        );
                      }}
                      onInputChange={(e) => {
                        e.preventDefault();
                      }}
                      onChange={(e) => {
                        e.preventDefault();
                      }}
                      disableClearable={true}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          return (
                            <Chip
                              key={option}
                              label={option.value}
                              {...getTagProps({ index })}
                              onDelete={() => handleDeleteSkill(index)}
                              sx={{ backgroundColor: option.validated ? theme.palette.primary.main : lightBlue[400] }}
                            />
                          );
                        })
                      }
                      sx={{
                        minWidth: '250px',
                        '& .MuiOutlinedInput-root': {
                          marginBottom: '0!important',
                        },
                        '& .MuiChip-root > .MuiChip-deleteIcon': {
                          color: 'rgb(255 255 255 / 66%)',
                        },
                        '& .MuiChip-root': {
                          color: 'white', // Customize the tag text color
                          margin: '4px', // Customize the tag margin
                        },
                      }}
                      disabled={editingFields}
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    [theme.breakpoints.down('lg')]: {
                      width: '100%',
                      ml: '0!important',
                    },
                  }}
                >
                  <CustomBtn
                    onClick={() => setOpenModalSkills(true)}
                    variant="outlined"
                    sx={{
                      px: 0,
                      minWidth: '166px',
                      [theme.breakpoints.up('lg')]: {
                        marginTop: '60px',
                        width: '100%',
                      },
                    }}
                    disabled={editingValidateFields}
                  >
                    VALIDATE SKILLS
                  </CustomBtn>
                </Box>
              </Stack>
              {errors.skillsCV && (
                <FormHelperText error id={'skillsCV'}>
                  {errors?.skillsCV && errors.skillsCV.length === 0
                    ? errors?.skillsCV?.txt
                    : 'All the skills must be validated for user'}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={0.5}>
            {/* LANGUAGES */}
            <Grid item xs={12} xl={8}>
              <Stack
                direction="row"
                spacing={2}
                alignItems={'flex-start'}
                justifyContent="center"
                sx={{ width: '100%' }}
                flexWrap={{ xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' }}
              >
                <Stack
                  height="100%"
                  justifyContent={'flex-end'}
                  sx={{
                    flexGrow: 1,
                    [theme.breakpoints.down('md')]: {
                      width: '100%',
                    },
                  }}
                >
                  <InputAutoCompleteV2
                    id="languagesCV"
                    size={'normal'}
                    label="Languages"
                    initialValue={fields.languagesCV}
                    error={handleErrorSingleOrValid('languagesCV')}
                    opts={languages}
                    onChangeInput={(e) => {
                      if (e.target.value !== fields.languagesCV) {
                        handleSave(e);
                      }
                    }}
                    multiple={true}
                    placeholder="Languages"
                    typeRenderOptions={'tags'}
                    isDisabled={editingFields}
                  />
                </Stack>
                <Box
                  sx={{
                    [theme.breakpoints.down('lg')]: {
                      width: '100%',
                      ml: '0!important',
                    },
                  }}
                >
                  <CustomBtn
                    variant="outlined"
                    sx={{
                      minWidth: '231px',
                      [theme.breakpoints.up('lg')]: {
                        marginTop: '41px!important',
                        width: '100%',
                      },
                    }}
                    onClick={() => handleValidateByUser('languagesCV')}
                    disabled={handleStateButtonvalid('languagesCV')}
                  >
                    VALIDATE LANGUAGES
                  </CustomBtn>
                </Box>
              </Stack>
            </Grid>
            {/* Certifications */}
            <Grid item xs={12} xl={8}>
              <Stack
                direction="row"
                spacing={2}
                alignItems={'flex-start'}
                justifyContent="center"
                sx={{ width: '100%' }}
                flexWrap={{ xs: 'wrap', sm: 'wrap', md: 'wrap', lg: 'nowrap' }}
              >
                <Stack
                  height="100%"
                  justifyContent={'flex-end'}
                  sx={{
                    flexGrow: 1,
                    [theme.breakpoints.down('md')]: {
                      width: '100%',
                    },
                  }}
                >
                  <InputTextOutlinedV2
                    id={'certificationsCV'}
                    size={'normal'}
                    type="text"
                    label="certifications"
                    error={handleErrorSingleOrValid('certificationsCV')}
                    initialValue={fields.certificationsCV}
                    onChangeInput={(e) => {
                      handleSave(e, initialStateRequest?.certificationsCV);
                    }}
                    isDisabled={editingFields}
                  />
                </Stack>
                <Box
                  sx={{
                    [theme.breakpoints.down('lg')]: {
                      width: '100%',
                      ml: '0!important',
                    },
                  }}
                >
                  <CustomBtn
                    variant="outlined"
                    sx={{
                      minWidth: '231px',
                      [theme.breakpoints.up('lg')]: {
                        marginTop: '41px!important',
                        width: '100%',
                      },
                    }}
                    onClick={() => handleValidateByUser('certificationsCV')}
                    disabled={handleStateButtonvalid('certificationsCV')}
                  >
                    VALIDATE CERTIFICATIONS
                  </CustomBtn>
                </Box>
              </Stack>
            </Grid>
            {/* indrustry experience */}
            <IndustryExperienceOptions
              handleSave={handleSave}
              editingFields={editingFields}
              rowOptions={fields.industry_experienceCV}
            />
          </Grid>
          {/*Work experience*/}
          <Grid container mt={3}>
            <Typography variant="h5" sx={{ fontWeight: '700' }}>
              EXPERIENCE
            </Typography>
          </Grid>
          {fields.workExperienceCV?.map((we, index) => (
            <RowResumeWorkExperience
              key={`we_${index}_RowResumeWorkExperience`}
              fieldsWe={we}
              errorsWe={errors?.workExperienceCV?.[index]}
              editing={editingFields}
              resumeRequired={fields.resumeRequired}
              index={index}
              onClearWorkExperience={handleClearIconClick}
              crearDisabled={handleStateButtonWe}
              onHandleWorkExperience={handleWorkExperience}
            />
          ))}
        </Grid>
        <Grid container mt={6}>
          <Stack direction="row" alignItems="center" marginLeft="auto">
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'left',
                color: 'nexxusGray.main',
                marginRight: 2,
              }}
            >
              Add new work experience
            </Typography>
            <IconButton disabled={editingFields} onClick={handleAddNewWorkExperience}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <ModalValidationSkills
        openModalSkills={openModalSkills}
        setOpenModalSkills={setOpenModalSkills}
        skills={fields?.skillsCV}
        skillsOptions={skillsOptions}
        handleValidate={handleValidate}
        onHandleDeleteSkill={handleDeleteSkill}
      />
    </>
  );
};

export default CandidateFormAutofilled;
