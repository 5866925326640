import MainLayout from 'components/MainLayoutRedesign/TasMainLayout';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

import { Box, Typography } from '@mui/material';
import ManageCountriesContent from './ManageCountriesContent';

const ManageCountries = (props) => {
  return (
    <MainLayout variant="transition" {...props} current="administration">
      <Box pt={2} pb={2} p={3}>
        <Typography
          variant="h4"
          mb={3}
          sx={{
            color: '#004DD3',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          }}
        >
          Country Blacklisting
        </Typography>
        <ManageCountriesContent />
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCountries);
