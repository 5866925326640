import { Box, Container, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useContext, useCallback } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../store/auth';
import { SideBar, TopBar, ThemeContext, CookiesFooter, SidebarContext } from '@nexxus/components';
import { useNavigate } from 'react-router-dom';
import { useSetLastUserActivityMutation } from '../../api/api';
import { useIdleTimer } from 'react-idle-timer';
import _ from 'lodash';
import Breadcrumbs from 'components/MainLayout/Breadcrumbs';
import MainContainer from 'components/MainLayout/MainContainer';
import useWindowSize from 'hooks/useWindowSize';
import useTasNotifications from 'hooks/tas/useTasNotifications';

export const NAVBAR_WIDTHS = {
  1280: {
    primary: 87,
    secondary: 103,
  },
  1366: {
    primary: 93,
    secondary: 110,
  },
  1920: {
    primary: 126,
    secondary: 149,
  },
};

const CustomNav = styled(Box)(({ theme }) => ({
  zIndex: '100000',
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
  },
  [theme.breakpoints.up(1280)]: {
    width: NAVBAR_WIDTHS[1280].primary + 'px',
  },
  [theme.breakpoints.up(1366)]: {
    width: NAVBAR_WIDTHS[1366].primary + 'px',
  },
  [theme.breakpoints.up(1920)]: {
    width: NAVBAR_WIDTHS[1920].primary + 'px',
  },
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingLeft: '5%',
  paddingRight: '5%',
}));

const SecondaryDrawerNav = styled(
  Drawer,
  {}
)(({ theme, open }) => ({
  ...(open && {
    '& .MuiDrawer-paper': {
      zIndex: '100000',
      background: '#1841A3!important',
      border: 'none!important',
      width: 160 + 'px',
      [theme.breakpoints.down(1280)]: {
        maxWidth: '180px',
      },
      [theme.breakpoints.up(1280)]: {
        width: NAVBAR_WIDTHS[1280].primary + NAVBAR_WIDTHS[1280].secondary + 'px',
      },
      [theme.breakpoints.up(1366)]: {
        width: NAVBAR_WIDTHS[1366].primary + NAVBAR_WIDTHS[1366].secondary + 'px',
      },
      [theme.breakpoints.up(1920)]: {
        width: NAVBAR_WIDTHS[1920].primary + NAVBAR_WIDTHS[1920].secondary + 'px',
      },
    },
  }),
  marginLeft: 0,
}));

const BreadcrumbsStyled = styled(Breadcrumbs)({});

const MainLayout = (props) => {
  const { current } = props;
  const [drawerWidth, setDrawerWidth] = React.useState(80);
  const [secondaryDrawerWidth, setSecondaryDrawerWidth] = React.useState(drawerWidth * 3);
  const { auth } = props;
  const lastLogin = auth?.lastLogin;
  const { setThemeName } = useContext(ThemeContext);
  const theme = useTheme();
  const isUpTo1280px = useMediaQuery(theme.breakpoints.up(1280));
  const isUpTo1366 = useMediaQuery(theme.breakpoints.up(1366));
  const isUpTo1920px = useMediaQuery(theme.breakpoints.up(1920));
  const history = useNavigate();
  const { openSecondary, setOpenSecondary, open: mobileOpen, setOpen: setMobileOpen } = useContext(SidebarContext);
  const [setLastUserActivity] = useSetLastUserActivityMutation();
  const { notifications, handleWidgetClick  } = useTasNotifications()
  const timeout_minutes = process.env.REACT_APP_SESSION_TIMEOUT_MINUTES ?? '15';
  const session_idle_timeout = 1000 * 60 * parseInt(timeout_minutes);
  const windowSize = useWindowSize();

  useIdleTimer({
    timeout: session_idle_timeout,
    throttle: 1000 * 30,
    onIdle: () => history('/logout'),
    onAction: () => setLastUserActivity(),
  });

  useEffect(() => {
    setThemeName('new');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    setMobileOpen(false);
    setOpenSecondary(false);
  }, [setMobileOpen, setOpenSecondary]);

  useEffect(() => {
    if (windowSize[0] < 1279) {
      handleClose();
    }
  }, [handleClose, windowSize]);


  useEffect(() => {
    //order is important
    if (isUpTo1920px) {
      setSecondaryDrawerWidth(NAVBAR_WIDTHS[1920].primary + NAVBAR_WIDTHS[1920].secondary);
      return setDrawerWidth(NAVBAR_WIDTHS[1920].primary);
    }
    if (isUpTo1366) {
      setSecondaryDrawerWidth(NAVBAR_WIDTHS[1366].primary + NAVBAR_WIDTHS[1366].secondary);
      return setDrawerWidth(NAVBAR_WIDTHS[1366].primary);
    }
    if (isUpTo1280px) {
      setSecondaryDrawerWidth(NAVBAR_WIDTHS[1280].primary + NAVBAR_WIDTHS[1280].secondary);
      return setDrawerWidth(NAVBAR_WIDTHS[1280].primary);
    }
  }, [isUpTo1366, isUpTo1280px, isUpTo1920px]);

  const handleLogout = async () => {
    history('/logout');
  };

  const handleGoToNotifications = () => {
    history('/tas/admin/notifications');
  };


  if (!props.auth?.user) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomNav component="nav" aria-label="navbar box">
        <SideBar
          {...props}
          mobileOpen={mobileOpen}
          handleSidebarOpen={(val) => setMobileOpen(val)}
          current={current}
          setModalHubspot={() => {}}
          drawerWidth={drawerWidth}
          auth={auth}
        />
      </CustomNav>
      <SecondaryDrawerNav
        sx={{
          width: {},
          zIndex: (theme) => theme.zIndex.drawer + 9999,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={openSecondary}
      >
        <SideBar
          {...props}
          mobileOpen={openSecondary}
          current={current}
          setModalHubspot={() => {}}
          drawerWidth={secondaryDrawerWidth}
          auth={auth}
          isPrimary={false}
        />
      </SecondaryDrawerNav>
      <Box
        onClick={() => {
          setOpenSecondary(false);
        }}
        width={'100%'}
        sx={{ maxWidth: '100%', overflow: 'hidden' }}
      >
        <TopBar
          onHandleLogOut={handleLogout}
          handleSidebarOpen={() => setMobileOpen(!mobileOpen)}
          lastLogin={lastLogin ?? ''}
          userName={props.auth.user.first_name}
          badgeNumber={notifications?.totalNotRead || 0}
          // @ts-ignore
          notifications={notifications?.notifications || []}
          handleGoToNotifications={handleGoToNotifications}
          user={props.auth.user}
          onHandleWidgetClick={handleWidgetClick}
          isTasAdmin
        />

        <Main style={{ minHeight: 'calc(100vh - 86px)' }}>
          <>
            <Container sx={{ my: 2 }}>
              <BreadcrumbsStyled />
            </Container>
            <MainContainer>{props.children}</MainContainer>
          </>
        </Main>
      </Box>
      <CookiesFooter />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
