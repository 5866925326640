import { ROLE_ADMIN, ROLE_SUPERTAS, ROLE_TAS, ROLE_SUPERCUSTOMER } from 'constants/users';
import Candidate from 'pages/tas/candidates/Candidate';
import CreateReport from 'pages/tas/reports/create/CreateReport';
import HistoricalView from 'pages/tas/reports/historicalView/HistoricalView';
import ListReports from 'pages/tas/reports/list/ListReports';
import ViewReport from 'pages/tas/reports/view/ViewReport';
import TagsPage from 'pages/tas/tags/TagsPage';
import UserAccessControl from 'pages/userAccessControl/UserAccessControl';
import { Route } from 'react-router-dom';
import CandidateSearch from '../pages/tas/candidateSearch/CandidateSearch';
import CreateClient from '../pages/tas/clients/CreateClient';
import ManageClients from '../pages/tas/clients/ManageClients';
import Dashboard from '../pages/tas/dashboard/Dashboard';
import EditProject from '../pages/tas/projects/EditProject/EditProject';
import ManageProjects from '../pages/tas/projects/ManageProjects/ManageProjects';
import ViewProject from '../pages/tas/projects/ViewProject/ViewProject';
import PrivateRoute from './privateRouter';
import AdminSkills from 'pages/tas/adminSkills/AdminSkills';
import ManageCountries from 'pages/ManageCountries';
import TasIntakeForm from 'pages/tas/intakeForm/index';
import IntakeList from 'pages/customer/IntakeList';
import Onboarding from 'pages/humanResources/Onboarding';
import NotificationCenter from 'pages/customer/NotificationCenter';

const tasRoutes = [
  {
    name: 'Dashboard',
    path: [
      '/tas/dashboard',
      '/tas/dashboard/active_projects/:customerId',
      '/tas/dashboard/active_projects/:customerId/:projectId',
    ],
    element: <Dashboard />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  // #TODO remove outdated candidate overview
  {
    name: 'Candidate Search',
    path: '/tas/candidates/search',
    element: (
      <CandidateSearch
        key="view:SearchCandidate"
        title={
          <>
            Find The Perfect <strong>Candidate!</strong>
          </>
        }
      />
    ),
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Blacklisted Candidates',
    path: '/tas/candidates/blacklisted',
    element: (
      <CandidateSearch
        key="view:Blacklisted"
        title={
          <>
            Find a blacklisted <strong>Candidate</strong>
          </>
        }
        only_blacklisted={true}
        showIncludeBox={false}
      />
    ),
    authorization: [ROLE_SUPERTAS, ROLE_TAS],
  },
  {
    name: 'Sonatafy Engineers',
    path: '/tas/candidates/staff',
    element: (
      <CandidateSearch
        key="view:Staff"
        title={
          <>
            Sonatafy <strong>Engineers</strong>
          </>
        }
        only_staff={true}
        showIncludeBox={false}
      />
    ),
    authorization: [ROLE_SUPERTAS, ROLE_TAS],
  },
  {
    name: 'New Candidate V2',
    path: '/tas/candidates/new',
    element: <Candidate editing={false} key={'create-new'} />,
    authorization: [ROLE_SUPERTAS, ROLE_TAS],
  },
  {
    name: 'New Candidate V2',
    path: ['/tas/candidates/:CandidateId', '/tas/candidates/:CandidateId/view'],
    element: <Candidate editing={true} key={`view-:CandidateId`} />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Edit Candidate',
    path: '/tas/candidates/:CandidateId/edit',
    element: <Candidate editing={false} key={`edit-:CandidateId`} />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Manage Customers',
    path: '/tas/customers',
    element: <ManageClients />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Create Client',
    path: '/tas/customer/create',
    element: <CreateClient key={'create-new'} />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'View/Edit Customer',
    path: '/tas/customer/:CustomerId/:modeCustomer',
    element: <CreateClient key={`view-:CustomerId-:modeCustomer`} />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'View Projects',
    path: '/tas/projects',
    element: <ManageProjects />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Create Project',
    path: '/tas/projects/create/based_customer/:ClientId',
    element: <EditProject key={'project-create-by-customer'} />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Create Project based on existing',
    path: '/tas/projects/create/based_project/:DuplicatedId',
    element: <EditProject key={'project-create-by-existing-project'} />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Create Project',
    path: '/tas/projects/create',
    element: <EditProject key={'project-create'} />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Pre-fill Intake Form',
    path: '/tas/projects/intake-form',
    element: <TasIntakeForm />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Project Intake Edit',
    path: '/tas/projects/intake/:ProjectId/edit',
    element: <TasIntakeForm key="intake-edit-:ProjectId" />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Saved Intake Forms',
    path: '/tas/projects/saved-intake-form',
    element: <IntakeList isTasAdmin />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Edit Project',
    path: '/tas/projects/:ProjectId/edit',
    element: <EditProject key={'project-edit-:ProjectId'} />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Edit Project View',
    path: '/tas/projects/:ProjectId/view',
    element: <ViewProject key={'project-view-:ProjectId'} />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Reports List',
    path: '/tas/reports',
    element: <ListReports />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Find Reports',
    path: '/tas/reports/search',
    element: <HistoricalView />,
    authorization: [ROLE_SUPERTAS, ROLE_ADMIN, ROLE_TAS],
  },
  {
    name: 'Create Report',
    path: '/tas/reports/create/:ProjectId',
    element: <CreateReport />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'View Report',
    path: '/tas/reports/:ReportId/view',
    element: <ViewReport />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Dashboard',
    path: '/hr/onboarding/position/:positionId/candidate/:candidateId/',
    element: <Onboarding isTasAdmin />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Tags',
    path: '/tas/admin/tags',
    element: <TagsPage />,
    authorization: [ROLE_SUPERTAS],
  },

  {
    name: 'Skills',
    path: '/tas/admin/skills',
    element: <AdminSkills />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Countries',
    path: '/tas/admin/countries',
    element: <ManageCountries />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Notifications',
    path: '/tas/admin/notifications',
    element: <NotificationCenter isTasAdmin />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'User Access Control',
    path: '/tas/admin/users',
    element: <UserAccessControl />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'User Access Control',
    path: '/customer/users',
    element: <UserAccessControl />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
];

export const TasRoutes = () => {
  return tasRoutes.map((route, index) => {
    if (Array.isArray(route.path)) {
      return route.path.map((path, key) => {
        return (
          <Route
            key={`tas-${index}-${key}`}
            path={path}
            element={<PrivateRoute roles={route.authorization}>{route.element}</PrivateRoute>}
          />
        );
      });
    }

    return (
      <Route
        key={`tas-${index}`}
        path={route.path}
        element={<PrivateRoute roles={route.authorization}>{route.element}</PrivateRoute>}
      />
    );
  });
};
