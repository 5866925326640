import moment from 'moment';
import React, { useContext, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import authService from '../../services/authService';
import { loginFailure, loginRequest, loginSuccess, logout } from '../../store/auth';
import { useConstructor } from '../../utils/useConstructor';

import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FormHelperText } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/system';
import classNames from 'classnames';
import SocialMediaLinks from 'components/Auth/SocialMedaLinks';
import Joi from 'joi';
import _ from 'lodash';
import { getPathDashboard } from 'utils/utils';
import forbes from '../../assets/img/forbes.png';
import logo from '../../assets/img/logo-1.png';
import video from '../../assets/img/nexxus.mp4';
import { setValidationMessages } from '../../utils/validationMessages';
import { ThemeContext } from '@nexxus/components';
// eslint-disable-next-line
// import { osVersion, osName, browserVersion, browserName, deviceType, deviceDetect, getUA } from 'react-device-detect';
import { deviceType } from 'react-device-detect';

const Login = (props) => {
  const theme = useTheme();
  useConstructor(() => {});
  const history = useNavigate();

  const stylesCheck = makeStyles({
    root: {
      padding: 0,
    },
  });

  const { setThemeName, themeName } = useContext(ThemeContext);

  useEffect(() => {
    setThemeName('old');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [checked, setChecked] = React.useState(false);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordView, setPasswordView] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const classesCheck = stylesCheck();
  const [searchParams] = useSearchParams();

  const schema = Joi.object({
    email: Joi.string()
      .required()
      .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
      .messages(setValidationMessages(['string', 'required'])),
    password: Joi.string()
      .required()
      .messages(setValidationMessages(['string', 'required'])),
  });

  useEffect(() => {
    (async () => {
      if (!_.isEmpty(props.auth.user)) {
        return history(getPathDashboard(props.auth), { replace: true });
      }

      const remember = await authService.getRememberUser();
      if (remember && remember !== '') {
        setEmail(remember);
        setChecked(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = async (fields) => {
    try {
      Joi.assert({ ...fields }, schema, {
        abortEarly: false,
        allowUnknown: true,
      });
      return null;
    } catch (error) {
      const errors = {};
      for (let item of error.details) {
        switch (true) {
          case item.path.length > 1 && !errors[item.path[0]]:
            errors[item.path[0]] = [];
            break;
          case item.path.length > 1:
            errors[item.path[0]][item.path[1]] = { [item.path[2]]: item.message };
            break;
          default:
            errors[item.context.label] = item.message;
        }
      }
      return errors || {};
    }
  };

  const doSubmit = async (e) => {
    e.preventDefault();
    try {
      props.loginRequest({ user: { email: email } });
      const valid = await validate({ email, password });
      if (valid) {
        return setErrors(valid);
      }

      const { user, tokens, notEmailVerified } = await authService.login({
        email,
        password,
        remember: checked,
        gather: {
          devWidth: window.innerWidth.toString(),
          devHeight: window.innerHeight.toString(),
          devType: deviceType,
        },
      });
      if (notEmailVerified) {
        history(`/reset-password?notEmailVerified=true&token=${tokens?.reset}&user=${user.email}`, { replace: true });
      } else {
        props.loginSuccess({ user: user, tokens: tokens, lastLogin: moment(new Date()).format('MM/DD/yyyy h:mm:ss a') });

        let path = '';
        if (searchParams.get('returnUrl')) path = searchParams.get('returnUrl');
        else path = getPathDashboard(user);

        history(path, { replace: true });
      }
    } catch (error) {
      let errorMessage;
      // console.log('showing error: ', error.response);
      if (error.response.status === 422 && error.response.data.error === 'user-inactive') {
        return setErrors({ inactive: true });
      } else if (error.response) errorMessage = error.response.data.message;
      else errorMessage = error.message;
      setErrors({ password: errorMessage });
      return;
    }
  };

  const ButtonStyle = styled(Button)({
    textTransform: 'none',
    fontSize: 16,
  });

  const inputRef = useRef();
  const rememberRef = useRef();

  return (
    <>
      {themeName === 'old' && (
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ textAlign: 'center', background: theme.palette.gradients.dark.tb, minHeight: '100vh' }}
          >
            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
              <img src={logo} alt="" className="" width={320} style={{ margin: '0 auto' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} component="form" onSubmit={doSubmit}>
              <Card sx={{ minWidth: 400, maxWidth: 450 }}>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginTop: 1,
                      marginBottom: 3,
                      color: 'nexxusGray.main',
                    }}
                  >
                    SIGN IN TO YOUR ACCOUNT
                  </Typography>
                  {errors.inactive && (
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        marginTop: 1,
                        marginBottom: 3,
                        color: 'nexxusGray.main',
                        lineHeight: '1.5',
                      }}
                    >
                      Account inactive, please contact support to reactivate the account via the following email:
                      <Link
                        style={{ fontSize: 14, color: '#047fc9', textDecoration: 'underline' }}
                        to="mailto:notifications@sonatafy.com?subject=Contact Support&body="
                      >
                        {' '}
                        notifications@sonatafy.com
                      </Link>
                    </Typography>
                  )}

                  <Box sx={{ textAlign: 'start', paddingX: 3, marginBottom: 3 }}>
                    <Typography>Email Address</Typography>
                    <OutlinedInput
                      ref={inputRef}
                      fullWidth
                      value={email}
                      onChange={(e) => {
                        setChecked(false);
                        setEmail(e.currentTarget.value);
                      }}
                      autoComplete="off"
                      // readOnly={focus}
                      // inputProps={{
                      //   onFocus: () => setFocus(() => false),
                      //   onBlur: () => setFocus(() => true),
                      // }}
                      size="small"
                      error={!!errors.email}
                      sx={{ pl: 1, pr: 0 }}
                      startAdornment={
                        <InputAdornment position="start">
                          <PersonIcon color={classNames(!errors.email ? 'nexxusBlue' : 'warning')} fontSize="small" />
                        </InputAdornment>
                      }
                      placeholder="email address"
                    />
                    {errors.email && (
                      <FormHelperText error id="accountId-error">
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box sx={{ textAlign: 'start', paddingX: 3, marginBottom: 1 }}>
                    <Typography sx={{}}>Password</Typography>
                    <OutlinedInput
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      size="small"
                      error={!!errors.password}
                      sx={{ pl: 1, pr: 0 }}
                      startAdornment={
                        <InputAdornment position="start">
                          <LockIcon color={classNames(!errors.password ? 'nexxusBlue' : 'warning')} fontSize="small" />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="start">
                          {passwordView === true && (
                            <VisibilityOffIcon
                              color={classNames(!errors.password ? 'nexxusBlue' : 'warning')}
                              fontSize="small"
                              style={{ cursor: 'pointer' }}
                              onClick={() => setPasswordView(passwordView ? false : true)}
                            />
                          )}
                          {passwordView === false && (
                            <VisibilityIcon
                              color={classNames(!errors.password ? 'nexxusBlue' : 'warning')}
                              fontSize="small"
                              style={{ cursor: 'pointer' }}
                              onClick={() => setPasswordView(passwordView ? false : true)}
                            />
                          )}
                        </InputAdornment>
                      }
                      type={passwordView ? 'text' : 'password'}
                      placeholder="password"
                      autoComplete="off"
                    />
                    {errors.password && (
                      <FormHelperText error id="accountId-error">
                        {errors.password}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box
                    sx={{
                      textAlign: 'start',
                      paddingX: 3,
                      marginBottom: 2,
                      marginTop: 2,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ marginRight: 1 }}>
                      <Checkbox
                        classes={{
                          root: classesCheck.root,
                        }}
                        id="remember_me"
                        ref={rememberRef}
                        onChange={() => setChecked(!checked)}
                        checked={checked}
                      />
                    </Box>
                    <label>Remember me</label>
                  </Box>
                  <Box sx={{ marginBottom: 5, px: 3 }}>
                    <Link to="/forgot-password" style={{ textDecoration: 'none' }}>
                      <Typography sx={{ fontSize: 14, color: 'nexxusBlueLight.main', textDecoration: 'underline' }}>
                        Forgot Password?
                      </Typography>
                    </Link>
                  </Box>
                  <Box sx={{ marginX: 7, marginBottom: 3 }}>
                    <ButtonStyle type="submit" variant="contained" color="nexxusBlue" fullWidth>
                      <Typography color="white">Login</Typography>
                    </ButtonStyle>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box sx={{ marginTop: 4, marginBottom: 2, display: 'flex', justifyContent: 'center' }}>
              <img src={forbes} alt="" className="" width={270} />
            </Box>
            <SocialMediaLinks />
            <Typography variant="body2" color="white" textTransform="uppercase" align="center" mt={2}>
              COPYRIGHT @2022 SONATAFY TECHNOLOGY, LLC. ALL RIGHTS RESERVED.
            </Typography>
            <Typography variant="body2" color="white" textTransform="uppercase" align="center" mb={2}>
              FOR MORE INFORMATION SEE OUR{' '}
              <Link to="/terms-and-conditions" style={{ textDecoration: 'none' }} target="_blank">
                <Typography
                  component={'span'}
                  sx={{ fontSize: 14, color: 'white', fontWeight: 600, textDecoration: 'underline' }}
                >
                  TERMS & CONDITIONS
                </Typography>
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ paddingLeft: 0, paddingTop: 0 }}>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <video autoPlay muted loop id="login-video">
                <source src={video} type="video/mp4" />
              </video>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (user) => {
      dispatch(loginRequest(user));
    },
    loginSuccess: (user, tokens) => {
      dispatch(loginSuccess(user, tokens));
    },
    loginFailure: () => {
      dispatch(loginFailure());
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
